export const GENERAL_REDUCER_ACTIONS = {
  GENERAL_STATE_CLEARED: '@general/stateCleared',
  SET_FEATURE_FLAG: '@general/setFeatureFlag'
} as const;

export const GENERAL_REDUCER_INITIAL_STATE: GeneralState = {
  featureFlags: {}
};

export type GeneralState = {
  featureFlags: {
    [key: string]: boolean | undefined;
    isMarketplaceV2Enabled?: boolean;
  };
};

export type GeneralActionTypes = {
  type: typeof GENERAL_REDUCER_ACTIONS[keyof typeof GENERAL_REDUCER_ACTIONS];
  error?: string;
  value?: Partial<GeneralState>;
};
