import { createTheme } from '@material-ui/core/styles';

export const themeV2 = createTheme({
  palette: {
    primary: {
      main: '#0058ff',
      light: '#EAF6FE',
      dark: '#064CA7'
    },
    secondary: {
      main: '#4caf50',
      light: '#caf1cc'
    },
    text: {
      primary: '#000',
      secondary: '#999999',
      hint: '#444444'
    },
    info: {
      main: '#eff1fb'
    },
    grey: {
      A100: '#f9fafa'
    },
    error: {
      main: '#E10050',
      light: '#FFEEEE'
    },
    warning: {
      main: '#ecbc74',
      light: '#F9F6D5'
    },
    success: {
      main: '#6ABC9F',
      light: '#ECFCE8'
    },
    headingBG: {
      main: '#EFF1FB'
    },
    border: {
      main: '#C7CBE0'
    },
    white: {
      main: '#fff'
    },
    bgGray: {
      main: '#F4F7FE'
    }
  },
  typography: {
    fontFamily: ['Heebo', 'Comfortaa'].join(','),
    button: {
      textTransform: 'none'
    }
  },
  shape: {
    borderRadius: 0
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '#root': {
          display: 'flex'
        }
      }
    },
    MuiTooltip: {
      tooltip: {
        fontSize: '1em'
      }
    }
  }
});
