import React, { lazy } from 'react';
import routes from 'components/Router/routes';
import { ExtendedRouteProps } from '../types';

const LiveModelPreviewPage = lazy(async () => import('../../../pages/LiveModelPreview'));
const TagViewerPage = lazy(async () => import('../../../pages/TagViewer'));
const TextFundamentalPage = lazy(async () => import('../../../pages/TextFundamental'));
const DashboardPage = lazy(async () => import('../../../pages/Dashboard'));
const RolledUpNumbersPage = lazy(async () => import('../../../pages/RolledUpNumbers'));

export const protectedRoutes: ExtendedRouteProps[] = [
  {
    path: routes.Live_PREVIEW,
    children: <LiveModelPreviewPage />,
    isProtected: true
  },
  {
    path: routes.TAG_VIEWER,
    children: <TagViewerPage />,
    isProtected: true
  },
  {
    path: routes.DOC_VIEWER,
    children: <TagViewerPage isDocument />,
    isProtected: true
  },
  {
    path: routes.TEXT_FUNDAMENTAL,
    children: <TextFundamentalPage />,
    isProtected: true
  },
  {
    path: routes.DASHBOARD,
    children: <DashboardPage />,
    isProtected: true
  },
  {
    path: routes.ROLLUP_NUMBERS,
    children: <RolledUpNumbersPage />,
    isProtected: true
  }
];
