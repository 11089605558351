import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Router as ReactRouter } from 'react-router-dom';
import { SET_LANGUAGE } from './localization/reducer';
import Header from './components/Header';
import Router from './components/Router';
import Popup from 'components/Popup';
import { History } from 'core';
import InitializeIntercom from 'intercom';
import { isLoggedIn } from 'core/modules/auth.utils';
import { actions as userDetailAction } from 'userDetail';
import { queryClient } from 'core';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { LicenseInfo } from '@mui/x-license-pro';
import { MenuV2 } from 'components/MenuV2';
import { DaloopaTheme } from 'ui-kit';
import { themeV2 } from 'main/config/theme';
import { CssBaseline, ThemeProvider } from '@material-ui/core';
import { Router as RouterV2 } from 'main/router/router';
import { generalState } from 'store/features';
import { useAppSelector } from 'store/hooks';

const DATA_GRID_LICENSE = process.env.REACT_APP_DATA_GRID_LICENSE;
if (DATA_GRID_LICENSE) {
  LicenseInfo.setLicenseKey(DATA_GRID_LICENSE);
}
export const App = () => {
  const dispatch = useDispatch();
  const { isMarketplaceV2Enabled } = useAppSelector(generalState).featureFlags;

  const theme = isMarketplaceV2Enabled ? themeV2 : DaloopaTheme;

  useEffect(() => {
    dispatch({
      type: SET_LANGUAGE,
      lang: 'en'
    });
    if (isLoggedIn()) {
      dispatch(userDetailAction.getUserDetails());
    }
    if (process.env.REACT_APP_ENABLE_INTERCOM === 'true' && isLoggedIn()) {
      InitializeIntercom();
    }
  }, [dispatch]);

  return (
    <ThemeProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={false} />
        <CssBaseline />
        <ReactRouter history={History}>
          {!isMarketplaceV2Enabled && (
            <>
              <Header />
              <Router />
            </>
          )}
          {isMarketplaceV2Enabled && (
            <>
              <MenuV2 />
              <RouterV2 />
            </>
          )}
          <Popup />
        </ReactRouter>
      </QueryClientProvider>
    </ThemeProvider>
  );
};
