import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import './datadog-monitoring';
import store from './store';
import { App } from './App';
import { StatsigProvider } from 'infra/statsig';

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={<></>}>
      <Provider store={store}>
        <StatsigProvider>
          <App />
        </StatsigProvider>
      </Provider>
    </Suspense>
  </React.StrictMode>,
  document.getElementById('root')
);
