import React, { lazy } from 'react';
import routes from 'components/Router/routes';
import { Redirect } from 'react-router-dom';
import { isLoggedIn, USE_SSO_LOGIN } from 'core/modules/auth.utils';
import { ExtendedRouteProps } from '../types';

const SignUpPage = lazy(async () => import('../../../pages/SignUp'));
const LoginPage = lazy(async () => import('../../../pages/Login'));
const RegisterPage = lazy(async () => import('../../../pages/Register'));
const ThankyouVerifyEmailPage = lazy(async () => import('../../../pages/ThankyouVerifyEmail'));
const ForgetPasswordPage = lazy(async () => import('../../../pages/ForgetPassword'));
const ResetPasswordPage = lazy(async () => import('../../../pages/ResetPassword'));

export const authRoutes: ExtendedRouteProps[] = USE_SSO_LOGIN
  ? []
  : [
      {
        path: routes.SIGN_UP,
        children: <SignUpPage />
      },
      {
        path: routes.LOGIN,
        children: isLoggedIn() ? <Redirect to={routes.HOME} /> : <LoginPage />
      },
      {
        path: routes.REGISTRATION,
        children: <RegisterPage />
      },
      {
        path: routes.THANKYOU_VERIFY_EMAIL,
        children: <ThankyouVerifyEmailPage />
      },
      {
        path: routes.FORGET_PASSWORD,
        children: <ForgetPasswordPage />
      },
      {
        path: routes.RESET_PASSWORD,
        children: <ResetPasswordPage />
      }
    ];
