import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  StatsigProvider as SProvider,
  StatsigOptions,
  StatsigUser,
  useStatsigClient,
  useStatsigUser
} from '@statsig/react-bindings';
import { isLoggedIn } from 'core/modules/auth.utils';
import { UserDetailSelectors } from 'userDetail';
import { setFeatureFlags } from 'store';

const STATSIG_CLIENT_SDK_KEY = 'client-6AfgU0iJZZ53gLe7FEshXvAX1pPqXDwLqJSa8uQNwT3';

const options: StatsigOptions = {
  environment: { tier: process.env.REACT_APP_ENV ?? 'development' }
};

const StatsigInner = ({ children }: { children: React.ReactNode }) => {
  const dispatch = useDispatch();
  const { updateUserAsync } = useStatsigUser();
  const isAuthenticated = isLoggedIn();
  const client = useStatsigClient();

  const params = useMemo(() => new URLSearchParams(window.location.search), []);

  const v2Enabled = client.checkGate('enable-marketplace-v2');

  const isMarketplaceV2Enabled = useMemo(() => !params.get('v1') && v2Enabled, [params, v2Enabled]);

  const email = useSelector(UserDetailSelectors.getUserDetails)?.email ?? '';

  const user: StatsigUser = useMemo(() => {
    if (!isAuthenticated) {
      return {
        userID: 'anonymous',
        email: ''
      };
    }

    return {
      userID: email,
      email
    };
  }, [email, isAuthenticated]);

  useEffect(() => {
    void updateUserAsync(user);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    dispatch(setFeatureFlags({ isMarketplaceV2Enabled }));
  }, [dispatch, isMarketplaceV2Enabled]);

  return <>{children}</>;
};

type Props = {
  children: React.ReactNode;
};

export const StatsigProvider = ({ children }: Props) => {
  const user: StatsigUser = useMemo(
    () => ({
      userID: 'anonymous',
      email: ''
    }),
    []
  );

  return (
    <SProvider sdkKey={STATSIG_CLIENT_SDK_KEY} options={options} user={user}>
      <StatsigInner>{children}</StatsigInner>
    </SProvider>
  );
};
