import React, { lazy } from 'react';
import routes from 'components/Router/routes';
import { ExtendedRouteProps } from '../types';

const ErrorPage = lazy(async () => import('../../../components/ErrorPage/index'));

export const errorRoutes: ExtendedRouteProps[] = [
  {
    path: routes.ERROR_404,
    children: <ErrorPage errorType="404" />
  },
  {
    path: routes.ERROR_500,
    children: <ErrorPage errorType="500" />
  },
  {
    path: routes.ERROR_401,
    children: <ErrorPage errorType="401" />
  },
  {
    path: routes.ERROR_403,
    children: <ErrorPage errorType="403" />
  }
];
