import React, { lazy } from 'react';
import routes from 'components/Router/routes';
import { ExtendedRouteProps } from '../types';

const ModelsPage = lazy(async () => import('../../../pages/Models'));
const InquireHerePopupPage = lazy(async () => import('../../../pages/InquireHerePopup'));
const RegisterThankYouPopupPage = lazy(
  async () => import('../../../components/RegisterThankYouPopup')
);

export const openRoutes: ExtendedRouteProps[] = [
  {
    path: routes.MODELS,
    children: <ModelsPage />
  },
  {
    path: routes.INQUIRE_HERE_POPUP,
    children: <InquireHerePopupPage />
  },
  {
    path: routes.REGISTER_THANKYOU_POPUP,
    children: <RegisterThankYouPopupPage />
  },
  {
    path: routes.HOME,
    children: <></>
  }
];
