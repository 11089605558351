import React, { memo } from 'react';
import { Switch, Route } from 'react-router-dom';
import { appRoutes } from './routes-config';
import { DefaultAuthenticatedRoute } from 'components/Router/DefaultAuthenticatedRoute';

export const Router = memo(() => {
  return (
    <Switch>
      {appRoutes.map(({ isProtected, ...route }) =>
        isProtected ? <DefaultAuthenticatedRoute {...route} /> : <Route {...route} />
      )}
    </Switch>
  );
});
