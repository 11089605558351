import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { useSubscription } from '@apollo/client';
import EarningsUpdateActionNotifications from 'components/EarningsUpdateActionNotifications/EarningsUpdateActionNotifications';
import {
  BellIcon,
  BookIcon,
  DaloopaLogoIcon,
  DaloopaPlusLogoIcon,
  DownloadIcon,
  HomeIcon,
  InstanceIcon,
  LoginIcon,
  LogoutIcon,
  MailIcon,
  MenuCollapseIcon,
  MenuExpandIcon
} from 'components/Icons';
import routes from 'components/Router/routes';
import { getUserInfo, GraphQL, redirectToSSOUrl } from 'core';
import { isLoggedIn, USE_SSO_LOGIN } from 'core/modules/auth.utils';
import loginActions from 'pages/Login/duck/actions';
import { UPDATE_NOTIFICATION_SUBSCRIPTION } from 'pages/Models/constants';
import { updateNotification } from 'pages/Models/duck/types';
import { UserDetailSelectors } from 'userDetail';
import { Mixpanel } from '../../infra/mixpanel';
import loginSelectors from '../../pages/Login/duck/selector';
import { modelsSelectors } from '../../pages/Models/duck/';
import modelsActions from '../../pages/Models/duck/actions';
import notificationsSelector from '../../pages/Models/duck/selector';
import {
  Container,
  ContentWrapper,
  MenuButton,
  MenuHeader,
  MenuMainNavigation,
  MenuSubNavigation,
  NotificationBadge,
  OldMarketPlaceButton
} from './MenuV2.styles';

export const MenuV2 = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const notificationButtonRef = useRef<HTMLButtonElement>(null);

  const [open, setOpen] = useState(true);
  const [notificationsOpen, setNotificationsOpen] = useState(false);

  const isAuthenticated = isLoggedIn();
  const isUserLoggedIn = useSelector(loginSelectors.isUserLoggedIn);
  const isFreeTier = useSelector(UserDetailSelectors.isFreeTier);

  const isEarningNotificationsEnabled = useSelector(modelsSelectors.isEarningNotificationsEnabled);
  const NOTIFICATIONS_POLLING = process.env.REACT_APP_NOTIFICATIONS_POLLING === 'true';
  const info = getUserInfo();

  const isPlus = useMemo(
    () => info?.productType === 'daloopa_plus' || info?.productType === 'daloopa_plus_time_ltd',
    [info]
  );

  const earningsUpdateNotificationsUnseenCount = useSelector(
    notificationsSelector.earningsUpdateNotificationsUnSeenCount
  );

  const notificationsCount = useMemo(
    () =>
      earningsUpdateNotificationsUnseenCount > 999
        ? '999+'
        : earningsUpdateNotificationsUnseenCount,
    [earningsUpdateNotificationsUnseenCount]
  );

  const handleToggleMenu = () => {
    setOpen((prev) => !prev);
  };

  const handleLoginClick = () => {
    if (USE_SSO_LOGIN) {
      redirectToSSOUrl();
    }
  };
  const handleLogout = () => {
    dispatch(loginActions.logout());
  };

  const handleShowNotifications = () => {
    setNotificationsOpen(true);
    Mixpanel.track('marketplace:menu:notification_bell_icon:click');
  };
  const handleHideNotifications = () => {
    setNotificationsOpen(false);
    Mixpanel.track('marketplace:menu:notifications:close');
  };

  useEffect(() => {
    if (
      isAuthenticated &&
      isEarningNotificationsEnabled &&
      NOTIFICATIONS_POLLING &&
      !location.pathname.includes('/src/')
    ) {
      dispatch(modelsActions.getUpdateNotifications());
    } else {
      dispatch(modelsActions.stopPolling());
    }
  }, [
    isEarningNotificationsEnabled,
    isAuthenticated,
    NOTIFICATIONS_POLLING,
    isUserLoggedIn,
    location,
    dispatch
  ]);

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(modelsActions.getEarningUpdateNotificationConfig());
    }
  }, [dispatch, isAuthenticated]);

  useSubscription<{
    earningUpdateNotifications: updateNotification[];
  }>(UPDATE_NOTIFICATION_SUBSCRIPTION, {
    skip:
      !isUserLoggedIn ||
      !isAuthenticated ||
      !isEarningNotificationsEnabled ||
      NOTIFICATIONS_POLLING,
    shouldResubscribe: false,
    client: GraphQL,
    onSubscriptionData: ({ subscriptionData: { data } }) => {
      if (data && data.earningUpdateNotifications) {
        dispatch(modelsActions.getUpdateNotificationSuccess(data.earningUpdateNotifications));
      }
    }
  });

  return (
    <Container open={open}>
      <ContentWrapper>
        <MenuHeader>
          {open && (
            <>
              {!isPlus && <DaloopaLogoIcon className="daloopa-icon" />}
              {isPlus && <DaloopaPlusLogoIcon className="daloopa-icon" />}
            </>
          )}
          <MenuButton onClick={handleToggleMenu}>
            {open ? <MenuCollapseIcon /> : <MenuExpandIcon />}
          </MenuButton>
        </MenuHeader>
        <MenuMainNavigation>
          <Link to={routes.HOME}>
            <MenuButton>
              <HomeIcon />
              {open && 'Home'}
            </MenuButton>
          </Link>
          <Link to={isFreeTier ? '#' : routes.EXCEL_PLUGIN}>
            <MenuButton disabled={isFreeTier}>
              <DownloadIcon />
              {open && 'Downloads'}
            </MenuButton>
          </Link>
          {process.env.REACT_APP_HELP_PAGE_LINK && (
            <a href={process.env.REACT_APP_HELP_PAGE_LINK} target="_blank" rel="noreferrer">
              <MenuButton>
                <BookIcon />
                {open && 'Documentation'}
              </MenuButton>
            </a>
          )}
        </MenuMainNavigation>
        <MenuSubNavigation>
          <a href="mailto:support@daloopa.com" target="_blank" rel="noreferrer">
            <MenuButton>
              <MailIcon />
              {open && 'Support'}
            </MenuButton>
          </a>
          {isAuthenticated && (
            <>
              <MenuButton ref={notificationButtonRef} onClick={handleShowNotifications}>
                <BellIcon />
                {open && 'Alerts'}
                {!!notificationsCount && (
                  <NotificationBadge className="notifications-badge">
                    {notificationsCount}
                  </NotificationBadge>
                )}
              </MenuButton>
              <EarningsUpdateActionNotifications
                anchorEl={notificationButtonRef.current}
                open={notificationsOpen}
                onClose={handleHideNotifications}
              />
            </>
          )}
          {!isAuthenticated && (
            <Link to={USE_SSO_LOGIN ? '#' : routes.LOGIN}>
              <MenuButton onClick={handleLoginClick}>
                <LoginIcon />
                {open && 'Login'}
              </MenuButton>
            </Link>
          )}
          {isAuthenticated && (
            <MenuButton onClick={handleLogout}>
              <LogoutIcon />
              {open && 'Logout'}
            </MenuButton>
          )}
          <OldMarketPlaceButton href={`${routes.HOME}?v1=true`} target="_blank" rel="noreferrer">
            <InstanceIcon />
            {open && <span>Switch to old Marketplace</span>}
          </OldMarketPlaceButton>
        </MenuSubNavigation>
      </ContentWrapper>
    </Container>
  );
};
